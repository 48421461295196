<template>
  <v-container fluid>
    <v-card flat>
      <v-toolbar tile elevation="1" color="indigo lighten-5">
        <BackButton />
        <v-tabs v-model="currentTab">
          <v-tab v-for="tab in PACKAGE_TABS" :key="tab.name" @click="$router.replace({ query: { currentTab: tab.name } })">
            {{ tab.label }}
          </v-tab>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="currentTab" class="test">
        <v-tab-item v-for="(item, index) in PACKAGE_TABS" :key="index">
          <component :is="item.component"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import BackButton from '@/components/common/BackButton'
import { PACKAGE_TABS } from '@/const/tabs.enum'

export default {
  name: 'PackagesList',

  components: {
    BackButton,
    TerminalPackages: () => import('@/components/support/logs/packages/TerminalPackages'),
    UnprocessedPackages: () => import('@/components/support/logs/packages/UnprocessedPackages'),
  },

  data: () => ({
    PACKAGE_TABS,
    currentTab: null,
  }),

  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(query) {
        this.setCurrentTab(query.currentTab)
      },
    },
  },

  methods: {
    setCurrentTab(tab) {
      const foundTab = this.PACKAGE_TABS.find((item) => item.name === tab)
      this.currentTab = foundTab ? this.PACKAGE_TABS.indexOf(foundTab) : 0
    },
  },
}
</script>
