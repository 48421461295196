export const LOG_TABS = Object.freeze([
  {
    name: 'event',
    label: 'Події',
  },
  {
    name: 'status',
    label: 'Статуси',
  },
])

export const PACKAGE_TABS = Object.freeze([
  {
    name: 'terminalPackages',
    label: 'Пакети пристроїв',
    component: 'TerminalPackages',
  },
  {
    name: 'unprocessedPackages',
    label: 'Необроблені пакети',
    component: 'UnprocessedPackages',
  },
])
